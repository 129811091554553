import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wander-apps/ui/dist/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-default\",\"src\":[{\"path\":\"./Graphik-Regular-Web.woff2\",\"weight\":\"400\"},{\"path\":\"./Graphik-Medium-Web.woff2\",\"weight\":\"500\"},{\"path\":\"./Graphik-Semibold-Web.woff2\",\"weight\":\"600\"},{\"path\":\"./Graphik-Bold-Web.woff2\",\"weight\":\"700\"}],\"display\":\"swap\"}],\"variableName\":\"GraphikRegular\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-headline\",\"src\":[{\"path\":\"./GT-Super-Display-Bold.woff2\",\"weight\":\"400\"},{\"path\":\"./GT-Super-Display-Bold.woff2\",\"weight\":\"500\"},{\"path\":\"./GT-Super-Display-Bold.woff2\",\"weight\":\"600\"},{\"path\":\"./GT-Super-Display-Bold.woff2\",\"weight\":\"700\"}],\"display\":\"swap\"}],\"variableName\":\"GtSuper\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/ui/src/components/Image/Image.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wander-apps/ui/src/components/Logo/wander-logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/ui/src/components/Popover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/ui/src/providers/NotificationsProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wander-apps/web/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/web/app/WanderChat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/web/components/LazyRender.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterSlotWrapper"] */ "/vercel/path0/wander-apps/web/features/footerSection/components/FooterSlot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/web/features/tracking/components/Tracking.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/web/features/user/components/User_AuthenticationDialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/web/features/userProfile/components/AskUserNameIfNeeded.tsx");
