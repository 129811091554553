import addReferralCode from '@/features/referralCode/actions/addReferralCode';
import getReferralCodeInfo from '@/features/referralCode/actions/getReferralCodeInfo';
import useUser from '@/features/user/hooks/useUser';
import useNotify from '@wander/ui/src/hooks/useNotify';
import * as cookie from 'cookie';
import { useEffect } from 'react';

const firstName = (fullName: string) => fullName.split(' ')[0];

const useTrackReferralCode = () => {
  const { user } = useUser();
  const notify = useNotify();

  useEffect(() => {
    if (user) {
      const referralCode = cookie.parse(document.cookie).referralCode;
      if (referralCode) {
        // TODO: Track referral code
        addReferralCode(referralCode)
          .then(async () => {
            const data = await getReferralCodeInfo(referralCode);
            notify.success({
              title: 'Welcome to Wander!',
              description: `${firstName(data.referrer.name)} sent you a gift! Get $250 off your first Wander booking`,
            });
          })
          .finally(() => {
            document.cookie = cookie.serialize('referralCode', '');
          });
      }
    }
  }, [user]);
};

export default useTrackReferralCode;
