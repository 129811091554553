'use client';

import OnDOMContentLoaded from '@wander/ui/src/components/OnDOMContentLoaded';
import { usePathname } from 'next/navigation';

import { Suspense, lazy } from 'react';

const Chat = lazy(() => import('@/features/chat'));

const useGetButtonClassName = () => {
  const pathname = usePathname();
  if (pathname?.startsWith('/trip-summary')) {
    return '!hidden';
  }

  return 'max-lg:!hidden';
};

const WanderChat = () => {
  const buttonClassName = useGetButtonClassName();
  return (
    <OnDOMContentLoaded>
      <Suspense fallback={null}>
        <Chat buttonClassName={buttonClassName} />
      </Suspense>
    </OnDOMContentLoaded>
  );
};

export default WanderChat;
