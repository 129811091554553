'use client';

import Dialog from '@wander/ui/src/components/Dialog';

import isNewUser from '@/features/user/actions/isNewUser';
import useUser from '@/features/user/hooks/useUser';
import { Suspense, lazy } from 'react';

const EnterName = lazy(() => import('./EnterName'));

const AskUserNameIfNeeded = () => {
  const { user } = useUser();

  if (user) {
    if (isNewUser(user)) {
      return (
        <Dialog open className='grid w-full gap-4 bg-black p-6 pt-4 md:w-fit md:min-w-[400px]'>
          <Suspense fallback={null}>
            <EnterName />
          </Suspense>
        </Dialog>
      );
    }
  }

  return null;
};

export default AskUserNameIfNeeded;
