'use client';

import { useSyncExternalStore } from 'react';
import yieldToMainThread from '../utils/yieldToMainThread';

const subscribePageLoad = (callback: () => void) => {
  const microTask = () => yieldToMainThread().then(callback);
  window.addEventListener('load', microTask);
  return () => window.removeEventListener('load', microTask);
};

const OnDOMContentLoaded = ({ children }: { children: React.ReactNode }) => {
  const show = useSyncExternalStore(
    subscribePageLoad,
    () => document.readyState === 'complete',
    () => false
  );

  return show ? children : null;
};

export default OnDOMContentLoaded;
