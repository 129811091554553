import { useEffect } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';

import trackPageView from '../client/actions/trackPageView';
import trackPageViewFallback from '../server/actions/trackPageView';

const useTrackPageView = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // biome-ignore lint/correctness/useExhaustiveDependencies: re triggers also on searchParams changes
  useEffect(() => {
    trackPageView(pathname, searchParams);
    trackPageViewFallback(window.location.href);
  }, [pathname, searchParams]);
};

export default useTrackPageView;
