'use client';

import { useState } from 'react';

import * as PopoverBase from '@radix-ui/react-popover';
import clx from '../utils/clx';

type Props = {
  children: React.ReactNode;
  open?: boolean;
  trigger?: React.ReactNode;
  closeIcon?: React.ReactNode;
  className?: string;
  arrowClassName?: string;
  closeButtonClassName?: string;
  sideOffset?: number;
  withArrow?: boolean;
  onClose?: (force?: boolean) => void;
};

const Popover = ({
  children,
  open,
  trigger,
  closeIcon,
  className,
  arrowClassName,
  closeButtonClassName,
  sideOffset = 5,
  withArrow = false,
  onClose,
}: Props) => {
  const [prevIsOpen, setPrevIsOpen] = useState(Boolean(open));
  const [isOpen, setOpen] = useState(Boolean(open));

  if (open !== undefined && prevIsOpen !== open) {
    setPrevIsOpen(open);
    setOpen(open);
  }

  return (
    <PopoverBase.Root
      open={isOpen}
      onOpenChange={(next) => {
        if (!next) {
          onClose?.();
        }

        setOpen(next);
      }}
    >
      {trigger ? (
        <PopoverBase.Trigger asChild>{trigger}</PopoverBase.Trigger>
      ) : (
        <PopoverBase.Anchor>
          <div className='max-md:hidden' />
        </PopoverBase.Anchor>
      )}
      <PopoverBase.Portal>
        <PopoverBase.Content
          className={clx(
            'data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade border-w-6003 z-[51] w-fit rounded-lg border bg-black p-8 will-change-[transform,opacity]',
            className
          )}
          sideOffset={sideOffset}
        >
          {children}
          {closeIcon && (
            <PopoverBase.Close className={clx(closeButtonClassName)} aria-label='Close'>
              {closeIcon}
            </PopoverBase.Close>
          )}
          {withArrow && <PopoverBase.Arrow className={clx('fill-w-6003', arrowClassName)} />}
        </PopoverBase.Content>
      </PopoverBase.Portal>
    </PopoverBase.Root>
  );
};

export default Popover;
