import * as cookie from 'cookie';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

const useTrackGoogleId = () => {
  const searchParams = useSearchParams();

  useEffect(() => {
    if (searchParams.has('gclid')) {
      const gclid = searchParams.get('gclid');
      if (gclid) {
        document.cookie = cookie.serialize('gclid', gclid);
      }
    }
  }, [searchParams]);
};

export default useTrackGoogleId;
