'use client';
import getUTMCampaign from '../../server/actions/getUTMCampaign';
import { analytics } from './analytics';

const pickName = (page: string) => {
  switch (true) {
    case page.startsWith('/countdown/'):
      return 'Trip countdown';
    default:
      return undefined;
  }
};

const trackPageView = async (page: string, searchParams?: URLSearchParams) => {
  analytics?.page(page, pickName(page), {
    context: {
      campaign: getUTMCampaign(searchParams),
    },
  });
};

export default trackPageView;
