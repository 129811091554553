'use client';
import isNewUser from '@/features/user/actions/isNewUser';
import type { MeUser } from '@wandercom/users-defs';
import pickTraits from '../../actions/pickTraits';
import getUTMCampaign from '../../server/actions/getUTMCampaign';
import { analytics } from './analytics';
import track from './track';

const trackSignInSignUp = async (user: MeUser) => {
  try {
    const traits = pickTraits(user);

    if (isNewUser(user)) {
      analytics.identify({ userId: user.id }, traits, {
        context: {
          campaign: getUTMCampaign(new URLSearchParams(window.location.search)),
        },
      });
    } else {
      analytics.track(
        'User Signed In',
        {},
        {
          campaign: getUTMCampaign(new URLSearchParams(window.location.search)),
          traits,
        }
      );
    }

    track({
      event: 'user_signed_in',
      data: {
        user_id: user.id,
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
      },
    });
  } catch (error) {
    console.error('Error tracking sign in or sign up', error);
  }
};

export default trackSignInSignUp;
