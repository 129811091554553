import type { MeUser } from '@wandercom/users-defs';

const pickTraits = (user: MeUser) => ({
  userId: user.id,
  email: user.email,
  dateOfBirth: user.dateOfBirth,
  phone: user.phoneNumber,
  firstName: user.firstName,
  lastName: user.lastName,
  address: {
    city: user.shippingAddress?.city,
    country: user.shippingAddress?.country,
    postalCode: user.shippingAddress?.postalCode,
    street: user.shippingAddress?.line1,
  },
});

export default pickTraits;
