import type { ReferralCodeInfo } from '@wandercom/referrals-defs';

import client from '@/services/fetcher/client';

const getReferralCodeInfo = async (code: string) => {
  try {
    const data: ReferralCodeInfo = await client.fetch(`/guest-api/referrals/code/${code}`).then((res) => res.json());

    return data;
  } catch {
    return undefined;
  }
};

export default getReferralCodeInfo;
