'use client';

import useTrackGoogleId from './useTrackGoogleId';
import useTrackLoginEvent from './useTrackLoginEvent';
import useTrackPageView from './useTrackPageView';
import useTrackReferralCode from './useTrackReferralCode';

const Tracking = () => {
  useTrackGoogleId();
  useTrackPageView();
  useTrackLoginEvent();
  useTrackReferralCode();

  return null;
};

export default Tracking;
