import client from '@/services/fetcher/client';

const addReferralCode = async (code: string): Promise<{ error?: string }> => {
  try {
    const response = await client.authFetch('/guest-api/referrals/use-code', {
      method: 'POST',
      body: JSON.stringify({
        code,
      }),
    });

    if (response.ok) {
      return {};
    }

    const { error } = await response.json();
    throw error;
  } catch (error) {
    return { error: String(error || 'Invalid code') };
  }
};

export default addReferralCode;
