'use client';

import { createRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import clx from '@wander/ui/src/utils/clx';

const SLOT_ID = 'footer-slot';

const slotRef = createRef<HTMLDivElement>();

type Props = {
  className?: string;
};

export const FooterSlotWrapper = ({ className }: Props) => {
  return <div ref={slotRef} id={SLOT_ID} className={clx('relative w-full', className)} />;
};

const useFooterSlot = () => {
  const [slot, setState] = useState<HTMLElement | null>(() => {
    if (typeof window === 'undefined') {
      return null;
    }

    return document.getElementById(SLOT_ID);
  });

  useEffect(() => {
    if (!slot) {
      setState(slotRef.current);
    }
  }, [slot]);

  return slot;
};

const FooterSlot = ({ children }: { children: React.ReactNode }) => {
  const slot = useFooterSlot();
  try {
    if (slot) {
      return <>{createPortal(<>{children}</>, slot)}</>;
    }
    return children;
  } catch (e) {
    console.error(`No matching element with id ${SLOT_ID} found`);
    return children as JSX.Element;
  }
};

export default FooterSlot;
