import type { Campaign } from '@segment/analytics-core/dist/types/events/interfaces';

const getUTMCampaign = (searchParams?: URLSearchParams): Campaign | undefined => {
  if (!searchParams) return;
  const campaign: Partial<Campaign> = {};

  if (searchParams.has('utm_campaign')) campaign.name = searchParams.get('utm_campaign') || undefined;
  if (searchParams.has('utm_medium')) campaign.medium = searchParams.get('utm_medium') || undefined;
  if (searchParams.has('utm_source')) {
    campaign.source = searchParams.get('utm_source') || undefined;
    campaign.name ||= campaign.source;
    campaign.medium ||= campaign.source;
  }
  if (searchParams.has('utm_content')) campaign.content = searchParams.get('utm_content') || undefined;
  if (searchParams.has('utm_term')) campaign.keyword = searchParams.get('utm_term') || undefined;
  if (searchParams.has('utm_term')) campaign.keyword = searchParams.get('utm_term') || undefined;
  if (searchParams.has('fbclid')) campaign.fbclid = searchParams.get('fbclid') || undefined;
  if (searchParams.has('gclid')) campaign.gclid = searchParams.get('gclid') || undefined;

  return campaign as Campaign;
};

export default getUTMCampaign;
