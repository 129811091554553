import useWanderUser from '@/features/user/hooks/useWanderUser';
import { useEffect } from 'react';
import trackSignInSignUp from '../client/actions/trackSignInSignUp';

const useTrackLoginEvent = () => {
  const user = useWanderUser();

  useEffect(() => {
    if (!user) {
      return;
    }
    trackSignInSignUp(user);
  }, [user]);
};

export default useTrackLoginEvent;
