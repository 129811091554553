'use client';

import { DialogAuthentication, useAuth } from '@/features/authentication';
import useAuthenticationDialog from '../hooks/useAuthenticationDialog';

const User_AuthenticationDialog = () => {
  const { isOpen, close } = useAuthenticationDialog();
  const {
    user: { token },
  } = useAuth();

  return <DialogAuthentication open={!token ? isOpen : false} onClose={close} />;
};

export default User_AuthenticationDialog;
