type UserInfo = {
  email?: string | null;
  name?: string | null;
  createdAt: Date;
  updatedAt: Date;
};

const isNewUser = (user: UserInfo) =>
  user?.email &&
  `${user.email?.split('@')[0]}` === user.name &&
  new Date(user.updatedAt).getTime() - new Date(user.createdAt).getTime() < 600000; // less than 10 minutes

export default isNewUser;
